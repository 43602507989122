<template>
  <div v-loading.fullscreen.lock="fullscreenLoading">
    <div class="header flex">
      <div class="logo_box flex">
        <img src="@/assets/user/logo.png" alt="" @click="$router.push('/')"/>
        <el-breadcrumb separator="/">
          <el-breadcrumb-item :to="{ path: '/user/userIndex' }">个人中心</el-breadcrumb-item>
          <el-breadcrumb-item><a>{{ $route.matched[1].meta }}</a></el-breadcrumb-item>
          <el-breadcrumb-item v-if="$route.matched[2] && !$route.matched[2].meta"><a>{{
              $route.matched[2].meta
            }}</a></el-breadcrumb-item>
        </el-breadcrumb>
      </div>
      <user_top_info/>
    </div>
    <div class="flex box">
      <!--侧边菜单-->
      <el-menu class="el-menu-vertical-demo el_menu_box" :default-active="menu_active" @open="handleOpen"
               @select="handleSelect" background-color="#108CDD" text-color="#fff" active-text-color="#ffd04b"
               :unique-opened="true" :router="true">
        <!-- :router="true" -->
        <template v-for="item in $store.state.nav_list ">
          <el-submenu v-if="item.children && item.children.length > 0"
                      :index="item.path ? item.path : item.id.toString()">
            <template slot="title">
              <div class="menu_box">
                <img :src="item.imgUrl" alt="icon" style="width: 20px; margin-right: 10px;"/>
                {{ item.title }}
              </div>
            </template>
            <el-menu-item v-for="child in item.children " :key="child.id" :index="child.path">
              <div class="menu_box">
                {{ child.title }}
                <!-- {{ child.order_status_total_num }} -->
                <!-- v-if="item.hasOwnProperty('order_status_total_num') && child.order_status_total_num != 0" -->
                <el-badge :value="child.order_status_total_num" class="item_badge"
                          v-if="child.hasOwnProperty('order_status_total_num') && child.order_status_total_num != 0"
                          :max="99">
                </el-badge>
                <el-badge :value="child.package_status_total_num" class="item_badge"
                          v-if="child.hasOwnProperty('package_status_total_num') && child.package_status_total_num != 0"
                          :max="99">
                </el-badge>
                <el-badge :value="child.draft_status_total_num" class="item_badge"
                          v-if="child.hasOwnProperty('draft_status_total_num') && child.draft_status_total_num != 0"
                          :max="99">
                </el-badge>
              </div>
            </el-menu-item>
          </el-submenu>
          <el-menu-item v-else :index="item.path" @click="goCenter(item)">
            <div class="menu_box">
              <img :src="item.imgUrl" alt="icon" style="width: 20px; margin-right: 10px;"/>
              {{ item.title }}
            </div>

          </el-menu-item>
        </template>

      </el-menu>
      <!--个人中心 user/index-->
      <div class="router_view">
        <router-view></router-view>
      </div>
    </div>
  </div>
</template>

<script>
import {mapMutations, mapState} from 'vuex'
import user_top_info from '@/components/user_top_info'

export default {
  created() {
    this.getUserInfo()
    // this.updateUserInfoAndRemoveChild()
  },
  computed: {
    ...mapState(['menu_active', 'nav_list', 'activeTitle', 'FillForm', 'pubNav', 'mediaOrder', 'manuscript', 'packOrder']),
    ...mapMutations(['SET_PUB_INDEX', 'SET_DATA', 'NAV_LIST_SPLICE']),
    // 监听vuex中的mediaOrder中的数据是否发生改变,如果发生改变则需要重新替换左侧导航栏中的提示条数
    mediaOrderStatus() {
      return this.mediaOrder
    },
    packOrderStatus() {
      return this.packOrder
    },
    manuscriptStatus() {
      return this.manuscript
    },

  },
  components: {
    user_top_info
  },
  watch: {
    mediaOrderStatus: {
      handler(newVal) {
        // localStorage.setItem('vuex', JSON.stringify(this.$store.state))
        this.getOrderNum(this.mediaOrder.category_id)
      },
      deep: true,
    },
    packOrderStatus: {
      handler(newVal) {
        // localStorage.setItem('vuex', JSON.stringify(this.$store.state))
        this.getOrderNum()
      },
      deep: true,
    },
    manuscriptStatus: {
      handler(newVal) {
        // localStorage.setItem('vuex', JSON.stringify(this.$store.state))
        this.getOrderNum(this.manuscript.category_id)
      },
      deep: true,
    },
  },
  mounted() {
    this.fullscreenLoading = true
    // 轮询查询次数
    // this.getOrderNum()
    this.timer = setInterval(this.getOrderNum(), 60000)
    this.fullscreenLoading = false

  },
  data() {
    return {
      fullscreenLoading: false,

      timer: undefined,
      // FillForm: {
      //   cover_image: '', //封面
      //   title: '',
      //   content: '', //编译器内容
      //   limit_time: '', //时间选择器
      //   file: '',
      //   url: '',
      //   video_url: '',
      //   original_url: '', //原文链接
      //   remark: '',
      //   medium_ids: '',
      //   package_ids: '',
      // },
      // shopObj:[]
      //用户信息
      userInfo: {},
      data: {
        value: {
          cover_image: '', //封面
          title: '',
          content: '', //编译器内容
          time: '', //时间选择器
          file: '',
          url: '',
          video_url: '',
          original_url: '', //原文链接
          remark: '',
          medium_ids: '',
          package_ids: '',
        }
      }
    }
  },
  methods: {
    handleOpen(key, keyPath) {
      // console.log(key, keyPath);
      // this.$store.commit('SETACTIVEMENU', keyPath[1] ? keyPath[1] : keyPath[0])
    },
    handleSelect(index, indexPath) {
      console.log('handleSelect', index, indexPath)
      // console.log('handleSelect', inxdex, indexPath)
      // console.log(indexPath[1])
      // console.log(indexPath[1].includes('/user/SetMenu/fill'))
      if (indexPath[1].includes('/user/SetMenu/fill')) {
        this.resetForm()
      }
      // console.log('index', index, indexPath);
      if (indexPath[1] == '/placard_user/pub_placard/pub_outdoors') {
        this.$store.commit('SET_PUB_INDEX', 0)
      }
      this.$store.commit('SETACTIVEMENU', indexPath[1])
    },
    goCenter(item) {
      // console.log('点击首页',item)
      // console.log(item.path)
      this.$store.commit('SETACTIVEMENU', item.path)
    },
    getOrderNum(id) {
      let data = {}
      if (id) {
        data.category_id = id
      }
      this.curlGet('api/users/order/num', data).then(res => {
        if (res.data.code) {
          // this.draft_status_num = res.data.data
          // ['draft_status_total_num']
          this.$store.state.nav_list.forEach((item, index) => {
            if (item.children) {
              this.nav_list[index].children.forEach((el, i) => {
                if (el.order_type == 'order') {
                  el.order_status_total_num = res.data.data.order_status_total_num
                }
                if (el.order_type == 'package') {
                  el.package_status_total_num = res.data.data.package_status_total_num
                }
                if (el.order_type == 'draft') {
                  el.draft_status_total_num = res.data.data.draft_status_total_num
                }

              })
            }
          });

          // console.log('draft_status_num:', this.draft_status_num);
        }
      })
    },
    // 重置填写稿件中的内容
    resetForm() {
      this.$store.state.FillForm = {
        cover_image: '', //封面
        title: '',
        content: '', //编译器内容
        time: '', //时间选择器
        file: '',
        url: '',
        video_url: '',
        original_url: '', //原文链接
        remark: '',
        medium_ids: '',
        package_ids: '',
      }
      this.$store.state.shopObj = []
    },
    // updateUserInfoAndRemoveChild() {
    //   this.$store.dispatch('updateUserInfo').then(() => {
    //     this.$store.dispatch('removeSpecificChild');
    //   });
    // }
  },
  beforeDestroy() {
    clearInterval(this.timer);
  },
}
</script>

<style lang=scss scoped>
@import '@/scss/userNav';
</style>