<template>
  <div class="user_box flex" v-show="user_info">
    <p class="userName">用户名: {{ user_info.username }}</p>
    <span class="lv_icon vip" v-show="user_info.group_id == 2"></span>
    <span class="lv_icon svip" v-show="user_info.group_id == 3"></span>
    <p class="New_message" @click="childRoute({
        title: '消息中心'
    })">最新消息[<span>{{ user_info.unread_notice_num }}</span>]</p>
    <button class="push_but" @click="go_user('/user/index')">媒介中心</button>
<!--    <button class="push_but" @click="go_user('/placard_user/placard_index')">广告中心</button>-->
    <button class="quit" @click="quit">退出</button>
  </div>
</template>

<script>
import {mapMutations} from 'vuex'

export default {
  computed: {
    ...mapMutations(['SETACTIVEMENU'])
  },
  mounted() {
    if (localStorage.getItem('user_info')) {
      this.user_info = JSON.parse(localStorage.getItem('user_info'))
    }
  },
  data() {
    return {
      user_info: this.$util.getLocalStorage('user_info'),
    }
  },
  methods: {
    go_user(url) {
      this.$store.commit('SETACTIVEMENU', url)
      this.$router.push(url)
    },
    // 退出
    quit() {
      this.$confirm('确定退出吗?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        this.curlPost('/api/user/logout').then(res => {
          if (res.data.code) {
            this.$message({
              offset: 60,
              type: 'success',
              message: res.data.msg
            });
            // this.$util.setLocalStorage('user_info', res.data.data, 200) // 写入local
            localStorage.clear()	// 清除local
            sessionStorage.clear()
            this.$router.push('/')
          }
        })

      })
    },
  },
}
</script>

<style lang=scss scoped>
.user_box {
  align-items: center;

  .lv_icon {
    display: block;
    width: 56px;
    height: 18px;
    margin-left: 15px;

  }

  p {
    font-size: 14px;
    color: #333333;

    span {
      color: #FF0000;
      font-size: 14px;
    }
  }

  .New_message {
    margin: 0 25px;
  }

  .push_but {
    color: #fff;
    font-size: 14px;
    border: 0;
    padding: 8px 15px 8px 15px;
    box-sizing: border-box;
    border-radius: 5px;
    background: rgba(16, 140, 221, 1);
    position: relative;
    margin-right: 10px;
  }

  .quit {
    color: #fff;
    font-size: 14px;
    border: 0;
    padding: 8px 10px 8px 30px;
    box-sizing: border-box;
    border-radius: 5px;
    background: rgba(222, 16, 16, 1);
    position: relative;

    &::before {
      content: "";
      width: 15px;
      height: 15px;
      background: url('~@/assets/user/quit.png') no-repeat;
      background-size: 100%;
      position: absolute;
      left: 10px;
      top: 10px;
    }
  }
}
</style>